import React from "react";

import Layout from "../../components/layout";
import {GettingStartedSidebar as Sidebar} from "../../components/sidebar";
import {GettingStartedBreadcrumb as Breadcrumb} from "../../components/breadcrumb";
import {AskHowToInfoAlert} from "../../components/alert";
import {KeyHeader} from "../../components/typography";

const pageTitle = "Code Examples";

const Page = () => (
    <Layout pageTitle={pageTitle}
            sidebarNav={<Sidebar/>}
            mobileNav={<Breadcrumb activeName={pageTitle}/>}
    >
        <KeyHeader className="mt-0">Code Examples</KeyHeader>

        <a href="/Examples/step1.cpp">Connecting to database (and error handling)</a>
        <div className="mb-3">
            This example shows how to connect to/disconnect from databases using the
            library. It also shows how to handle errors.
        </div>

        <a href="/Examples/step2.cpp">Executing a simple SQL command</a>
        <div className="mb-3">
            This example show the example of executing a simple query without any
            parameters and result set.
        </div>

        <a href="/Examples/step3.cpp">Binding input parameters</a>
        <div className="mb-3">
            This example shows 2 methods for providing input parameters:
            direct assignment and stream-based.
        </div>

        <a href="/Examples/step4.cpp">Executing select query (and fetching result set)</a>
        <div className="mb-3">
            This example shows how to process result set using SQLAPI++.
        </div>

        <a href="/Examples/step5.cpp">Binding LongBinary, LongChar, BLob and CLob data</a>
        <div className="mb-3">
            This example shows 2 methods for binding Long or Lob data: traditional
            (bind all the data in one piece) and piecewise (using user defined
            callbacks for providing data).
        </div>

        <a href="/Examples/step6.cpp">Fetching LongBinary, LongChar, BLob and CLob data</a>
        <div className="mb-3">
            This example shows 2 methods for fetching Long or Lob data: traditional
            (read all the data in one piece) and piecewise (using user defined
            callbacks for reading data).
        </div>

        <a href="/Examples/step7.cpp">Multithreading support and cancelling queries</a>
        <div className="mb-3">
            This example shows how to create a thread with long running query.
        </div>

        <a href="/Examples/bulk_fetch.cpp">Fetching records in bulk</a>
        <div className="mb-3">
            This example demonstrates the use of {' '}<code>"PreFetchRows"</code>{' '} option
            to optimize the reading performance when returning back large result sets. The code fetches
            the data with and without the option and reports time spent in each case.
        </div>

        <a href="/Examples/refcursors.cpp">Using Oracle REF CURSORs</a>
        <div className="mb-3">
            This example demonstrates how to get Oracle REF CURSORs from a stored
            procedure and than fetch it using SQLAPI++ library.
        </div>

        <a href="/Examples/nestedcursors.cpp">Using Oracle nested cursors</a>
        <div className="mb-3">
            This example shows how SQLAPI++ handles queries with nested cursors.
        </div>

        <AskHowToInfoAlert/>
    </Layout>
);

export default Page;
